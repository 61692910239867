import React from "react";
import diamond from '../../resources/images/about/icons/diamond.svg';
import people from '../../resources/images/about/icons/people.svg';
import electronics from '../../resources/images/about/icons/electronics.svg';
import mountain from '../../resources/images/about/icons/mountain.svg';
import hands from '../../resources/images/about/icons/hands.svg';

import './style.scss';

const Card = ({image, title, text, className = ''}) => (
    <div className={`card ${className}`}>
        <div className={'image-wrapper'}>
            <img src={image} alt={''}/>
        </div>
        <div className={'text'}>
            <h3>{title}</h3>
            <span>{text}</span>
        </div>
    </div>
)

const CardsSection = React.forwardRef(({title,isClientFacing = true},ref) => {

    return (
        <section className={'four-cards whiteBg'} ref={ref}>
            {title && <h2>{title}</h2>}
            <div className={'wrapper'}>
                <Card image={diamond} className={'diamond'} title={'QUALITY'}
                      text={'We strive for the highest quality of products'}/>
                {isClientFacing && <Card image={people} title={'PARTNERSHIP'} text={'Building long term partnerships with our clients'}/>}
                {!isClientFacing && <Card image={hands} title={'FRIENDSHIP'} text={'Working every day with our good friends'}/>}
                <Card image={electronics} title={'INNOVATION'}
                      text={'Pushing the limits of possible with innovative solutions'}/>
                <Card image={mountain} title={'PASSION'} text={'Creating with love, passion and code'}/>
            </div>
        </section>
    )
});

export default CardsSection;