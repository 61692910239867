import React, {useState} from "react";
import useForm from "react-hook-form";
import Utils from '../../utils/utils';
import svgLoader from '../../resources/images/three-dots.svg';
import CodicateImage from "../codicate-image";
import * as yup from "yup";


const encode = (data) => {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        let innerData = data[key];
        //handle files
        if (typeof innerData === 'object' && innerData !== null) {
            innerData = innerData[0];
        }
        formData.append(key, innerData);
    }

    return formData;
}
const ThankYou = ({name}) => {
    const displayName = name.toUpperCase();
    return (
        <div className="thank-you">
            Thank you <strong>{displayName}</strong> we will contact you shortly
        </div>
    )
}

const SUPPORTED_FORMATS = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/jpeg', 'image/png']
const schema = yup.object().shape({
    full_name: yup.string().required("Full name is required"),
    cv:
        yup
            .mixed()
            .test("fileSize", "The file is too large", (value) => {
                return value && value[0] && value[0].size <= 3150000;
            })
            .test("type", "File type is no supported", (value) => {
                return value && value[0] && SUPPORTED_FORMATS.includes(value[0].type);
            })
            .test("required", "CV is required", (value) => {
                return value && value[0];
            }),
    email: yup.string().email().required("Email is required"),
    phone: yup.string()
        .required("Phone number is required")
        .matches(
            /^[0][5][0|2|3|4|5|8|9]{1}[-]{0,1}[0-9]{7}$/g,
            "Invalid phone number"
        ),

});

const CareerForm = ({SubmitButton, path, positionName, formName}) => {

    const {register, handleSubmit, errors} = useForm({validationSchema: schema});

    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const [userName, setUserName] = useState('');
    // const textAreaMessage = useRef();

    const onSubmit = (async (data, e) => {
        if (typeof window === 'undefined') return;
        e.preventDefault();
        // //delay the loading a bit so it will not flink
        // setTimeout(()=>{
                setSubmitLoading(true);
        // },600);
        setSubmitError(false);

        fetch(path, {
            method: "POST",
            body: encode({
                "form-name": formName,
                ...data
            })
        }).then(response => {
            setUserName(data.full_name || "");
            setSubmitLoading(false);
            setSubmitSuccess(true);


            Utils.gTagEvent({
                category: 'Career Form',
                action: 'Submit Form',
                label: path
            })
            // setShowMessage('Thank you! We\'ll be in touch!');
        }).catch(err => {
                setSubmitError(true);
                setSubmitLoading(false);
            }
        );

    });


    return (
        <div className={`form-container ${submitSuccess ? 'success' : ''}`}>
            {
                !(submitSuccess || submitLoading) &&
                <form action={path} method="POST" name={formName} data-netlify={true}
                      onSubmit={handleSubmit(onSubmit)}>
                    <div className={`form-content`}>
                        <input type="hidden" name="form-name" value="contact"/>
                        <label htmlFor={'full_name'}>FULL NAME</label>
                        <input
                            placeholder="Enter your name"
                            className={errors.full_name ? 'invalid' : ''} name={'full_name'} id={'full_name'}
                            type={'text'}
                            ref={register}/>
                        <label htmlFor={'email'}>EMAIL</label>
                        <input
                            placeholder="Enter your email"
                            className={errors.email ? 'invalid' : ''} name={'email'} id={'email'} type={'text'}
                            ref={register}/>
                        <label htmlFor={'phone'}>PHONE</label>
                        < input
                            placeholder="Enter your phone number"
                            className={errors.phone ? 'invalid' : ''} name={'phone'} id={'phone'} type={'text'}
                            ref={register}/>
                        <input type={'file'} name={'cv'} ref={register} className={errors.cv ? 'invalid' : ''}/>
                        <p>{errors.cv && <>{errors.cv.message}<br/></>}Allowed file types : doc, docx, pdf, jpg,
                            png. Must be less than 3MB.</p>
                        <input type={'hidden'} value={positionName} name={'position'} ref={register}/>
                        <SubmitButton/>
                    </div>

                    {
                        submitError &&
                        <p>An error occur, please try again.</p>
                    }
                </form>


            }
            {
                (submitLoading && !(submitSuccess || submitError)) &&
                <div className="loading">
                    <CodicateImage alt="icon" img={svgLoader}/>
                </div>
            }
            {
                submitSuccess &&
                <ThankYou
                    name={userName}
                />
            }



        </div>
    )
};

export default CareerForm;