import React, {useState} from "react";
import Layout from "../components/layout"
import GridSquares from "../components/grid-squares/index";
import {graphql} from 'gatsby';
import ImagesSection from "../components/about-images-section";
import CardsSection from "../components/cards-section";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import CareerForm from "../components/career-form";


const Careers = (props) => {

    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    };
    if (typeof window !== "undefined") {
        // eslint-disable-next-line global-require
        require("smooth-scroll")('a[href*="#"]')
    }

    const {allWordpressPage} = props.data;

    const jobs = allWordpressPage.edges.map(edge => (
        {
            title: edge.node.title,
            body: edge.node.content,
            order: edge.node.menu_order,
        }
    ));
    //jobs.sort((a, b) => (a.order > b.order) ? 1 : -1);

    return (
        <Layout hasGrisSquares={false} hasMovingSquares={false} className="page-careers" isIncludeFooter={false}
                yoastData={props.data.wordpressPage.yoast || null}
                seoTitle={props.data.wordpressPage.acf.seoTitle}
                pageTitle={props.data.wordpressPage.title}
        >
            <GridSquares/>
            <GridSquares/>

            <section className="join blueBg">
                <h1>Join our family</h1>
                <p>If you got the passion and the skills <br/>
                    come and join us to lift up your career</p>
                <a href="#roles">See Open Roles</a>
            </section>
            <section className="about-story whiteBg">
                <GridSquares/>
                <div className="text-area">
                    <h2>Meet our team</h2>
                    <p>Our success lies in gathering the best people who live, breathe and love web development.<br />
                        This is how we have been able to create products with very high standards for more than 15 years.<br />
                        We believe that in order for Codicate to thrive, each of the Codicators need to fulfill themselves and push themselves to the maximum.<br />
                        Our innovation comes from below, from every single employee. </p>
                </div>
            </section>
            <ImagesSection/>
            <CardsSection title="Our values" isClientFacing={false}/>
            <section className="roles blueBg" id="roles">
                <GridSquares rowsAmount={26}/>
                <h2>Open roles</h2>
                <Accordion defaultActiveKey='2'>
                    {
                        jobs.map((item, index) => {
                            const i = index + 1;
                            return <Card key={i}>
                                <Accordion.Toggle as={Card.Header} eventKey={i} onClick={() => toggleActive(`${i}`)} className={activeId === `${i}` ? 'isToggled' : null} >
                                    {item.title}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={i}>
                                    <Card.Body><div className="description"><div dangerouslySetInnerHTML={{__html: item.body}}></div></div>
                                        <CareerForm
                                            SubmitButton={() => <button>APPLY NOW</button>}
                                            path={props.location.pathname}
                                            formName="careers"
                                            positionName={item.title}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        })
                    }
                </Accordion>
            </section>

        </Layout>
    );
};

export default Careers

export const query = graphql`
    query {
        allWordpressPage(filter: {template: {eq: "careers-role.php"}, status: {eq: "publish"}}){
            edges {
                node {
                    title
                    content
                    menu_order
                }
            }
        }
        wordpressPage(template: {eq: "careers.php"}){
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
            }
        }
        flash: file(relativePath: { eq: "about/timeline/flash.png"}) {
            childImageSharp {
                fluid(maxWidth: 400){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
       

    }
`;