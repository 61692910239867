import React from 'react';
import './style.scss';
import {graphql, useStaticQuery} from "gatsby";
import CodicateImage from "../codicate-image/index";


const ImagesSection = React.forwardRef((props, ref) => {
    const data = useStaticQuery(graphql`
        query {
            guys1: file(relativePath: { eq: "about/footer/1.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2676){
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            guys2: file(relativePath: { eq: "about/footer/2.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2289){
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            guys3: file(relativePath: { eq: "about/footer/3.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1089){
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            guys4: file(relativePath: { eq: "about/footer/4.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2676){
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <div className={'images-section'} ref={ref}>
            <CodicateImage blur className={`imageContainer TopLeft`} img={data.guys3} />
            <div className={`imageContainer MiddleLeft`}     style={{background: `var(--purple-brown)`}}>Code <span>+</span> Dedication <br/><span>=</span> Codicate</div>
            <CodicateImage blur className={`imageContainer TopRight`} img={data.guys1} />
            <div className={`imageContainer BottomLeft`}>
                <CodicateImage blur className={`image`} img={data.guys2} />
            </div>
            <CodicateImage blur className={`imageContainer BottomRight`} img={data.guys4} />
        </div>
    )
});

export default ImagesSection